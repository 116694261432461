<template>
  <div class="app-home">
    <swiper ref="mySwiper" :options="swiperOptions" class="swiper">
      <swiper-slide v-for="(item, index) in banners" :key="index">
        <img :src="item" alt="" />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="type-title">热门课程展示</div>
    <ul class="type-list">
      <li v-for="(item, index) in courseList" :key="index">
        <img :src="item.imgSrc" alt="" />
        {{ item.title }}
      </li>
    </ul>
    <div class="type-title">师资队伍展示</div>
    <div class="teacher-con">
      <ul class="teacher-list">
        <li v-for="(item, index) in teacherList" :key="index">
          <img :src="item.imgSrc" alt="" />
          {{ item.title }}
        </li>
      </ul>
    </div>
    <div class="type-title">咨询</div>
    <div class="ask-form">
      <div class="form-group">
        <input
          type="number"
          placeholder="请输入手机号码"
          v-model="form.phone"
          @input="
            form.phone != '' ? (errorText = '') : (errorText = '请输入手机号')
          "
        />
      </div>
      <div class="form-group codeForm">
        <textarea
          placeholder="请输入咨询内容"
          name=""
          id=""
          cols="30"
          rows="4"
          v-model="form.content"
        ></textarea>
      </div>
      <div class="error">{{ errorText }}</div>
      <button class="btn" @click="ask">提 交</button>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide, directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
export default {
  components: { swiper, swiperSlide },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      form: {},
      errorText: "",
      banners: [
        require("@/assets/images/app/banner/banner1.jpg"),
        require("@/assets/images/app/banner/banner2.jpg"),
        require("@/assets/images/app/banner/banner3.jpg"),
        require("@/assets/images/app/banner/banner4.jpg"),
      ],
      courseList: [
        {
          imgSrc: require("@/assets/images/app/course/2.jpg"),
          title: "家庭早教指导",
        },
        {
          imgSrc: require("@/assets/images/app/course/3.jpg"),
          title: "产褥期瘦身",
        },
        {
          imgSrc: require("@/assets/images/app/course/4.jpg"),
          title: "辅食添加",
        },
        {
          imgSrc: require("@/assets/images/app/course/5.jpg"),
          title: "月子餐",
        },
        {
          imgSrc: require("@/assets/images/app/course/6.jpg"),
          title: "早产儿护理",
        },
        {
          imgSrc: require("@/assets/images/app/course/7.jpg"),
          title: "追奶指导",
        },
      ],
      teacherList: [
        {
          imgSrc: require("@/assets/images/app/teacher/1.jpg"),
          title: "毕鸿文",
        },
        {
          imgSrc: require("@/assets/images/app/teacher/2.jpg"),
          title: "付亚辉",
        },
        {
          imgSrc: require("@/assets/images/app/teacher/3.jpg"),
          title: "孙月",
        },
        {
          imgSrc: require("@/assets/images/app/teacher/4.jpg"),
          title: "周彩霞",
        },
        {
          imgSrc: require("@/assets/images/app/teacher/5.jpg"),
          title: "朱凤霞",
        },
        {
          imgSrc: require("@/assets/images/app/teacher/6.jpg"),
          title: "王增珠",
        },
        {
          imgSrc: require("@/assets/images/app/teacher/7.jpg"),
          title: "王卫东",
        },
      ],
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        loop: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 3000,
        },
        // Some Swiper option/callback...
      },
    };
  },
  mounted() {},
  methods: {
    ask() {
      if (this.check()) {
        this.$ajax.send({
          url: "/employer/contact/index/add",
          data: this.form,
          type: "post",
          success: (data) => {
            if (!!data && data.code == "1001") {
              this.$msgBox.show({
                title: "温馨提示",
                content: "提交成功，我们的客服会尽快联系您！",
                confirmBtnText: "好的",
                isShowCancelBtn: false,
                confirm: () => {
                  this.form = {};
                },
              });
            }
          },
        });
      }
    },
    check() {
      if (!this.form.phone) {
        this.errorText = "请输入手机号";
        return false;
      } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.form.phone)) {
        this.errorText = "请输入正确的手机号码";
        return false;
      } else if (!this.form.content) {
        this.errorText = "咨询内容不能为空";
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style lang="scss">
.app-home {
  .swiper {
    width: 100%;
    height: 360px;
    .swiper-pagination {
      bottom: 0px;
      left: 340px;
      z-index: 100;
    }
    .swiper-pagination-bullet {
      margin: 0 5px;
    }
    .swiper-pagination-bullet-active {
      background-color: #f29b76;
    }
  }
}
</style>
<style lang="scss" scoped>
.app-home {
  background: #f8f8f8;

  .type-title {
    background: #e67871;
    height: 80px;
    font-size: 28px;
    padding: 0 30px;
    line-height: 80px;
    margin-top: 30px;
    color: #fff;
  }
  .type-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 30px 30px 30px;
    background: #fff;
    li {
      width: 330px;
      margin-top: 30px;
      text-align: center;
      line-height: 40px;
      img {
        width: 100%;
        height: 220px;
        border: #eee 1px solid;
      }
    }
  }
  .teacher-con {
    padding: 30px 0;
    width: 100%;
    overflow-x: auto;
    background: #fff;
    .teacher-list {
      display: flex;
      flex-wrap: nowrap;
      padding: 0 30px;
      li {
        width: 240px;
        margin-right: 30px;
        text-align: center;
        line-height: 40px;
        flex-shrink: 0;
        img {
          width: 240px;
          height: 240px;
          border-radius: 50%;
          border: #eee 1px solid;
        }
      }
    }
  }
  .ask-form {
    padding: 30px 30px 80px 30px;
    background: #fff;
    .form-group {
      margin-bottom: 20px;
      &:nth-child(2) {
        border-bottom: none;
      }
      label {
        font-size: 24px;
        width: 120px;
        display: inline-block;
      }
      input {
        width: 100%;
        border: #f1f2f3 1px solid;
        padding: 20px;
        font-size: 26px;
        &::placeholder {
          color: #c5c5c5;
        }
      }
      textarea {
        width: 100%;
        border: #f1f2f3 1px solid;
        padding: 20px;
        font-size: 26px;
        &::placeholder {
          color: #c5c5c5;
        }
      }
    }

    button {
      width: 100%;
      height: 88px;
      background: linear-gradient(135deg, #fecd74 0%, #fc9e40 100%);
      border-radius: 8px;
      color: #fff;
      font-size: 32px;
      border: none;
      margin-top: 50px;
    }

    .error {
      height: 40px;
      font-size: 28px;
      line-height: 40px;
      color: #d51f13;
    }
  }
}
</style>
